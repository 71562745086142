<template>
  <v-row class="pt-5" align="center" justify="center" no-gutters>
    <template v-if="isLoadingContracts">
      <v-col
        v-for="index in Array.from(Array(3).keys())"
        :key="index"
        class="col-12 d-flex flex-row align-center py-1"
      >
        <v-icon color="#95AFDA" left>mdi-file</v-icon>
        <v-skeleton-loader class="mb-0 pb-0" type="text" width="100%"></v-skeleton-loader>
      </v-col>
    </template>

    <template v-if="contracts.length">
      <v-col
        v-for="document in documentsToShow"
        :key="document.id"
        class="col-12 py-1 text-truncate"
      >
        <v-icon color="#95AFDA" left>mdi-file</v-icon>

        <a href="#" class="text-decoration-none" @click.prevent="onOpenUndoneDocument(document.id)">
          <span class="file-name__title text-decoration-underline">{{ document.title }}</span>
        </a>
      </v-col>

      <v-col v-if="canShowOtherDocumentsButton" class="col-12 mt-6">
        <v-btn color="primary" outlined @click="showAll = true">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('general.other_documents') }}
        </v-btn>
      </v-col>
    </template>

    <v-col v-else class="col-12 file-name__title px-8 py-1">
      {{ $t('no_next_contracts_to_sign') }}
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';
import { internalApiRequest } from '@/shared/services/apiService';

export default defineComponent({
  name: 'ContractCreatedUndoneContractsLinks',
  data() {
    return {
      contracts: [],
      loadedContracts: false,
      showAll: false,
    };
  },
  computed: {
    canShowOtherDocumentsButton() {
      return !this.showAll && 5 < this.contracts?.length;
    },
    documentsToShow() {
      return this.showAll ? this.contracts : this.contracts?.slice(0, 5);
    },
    isLoadingContracts() {
      return !this.loadedContracts;
    },
  },
  mounted() {
    internalApiRequest({
      method: 'POST',
      path: `api/v1/workspaces/${this.$route.params?.workspaceId}/contracts`,
      data: {
        workspace_id: this.$route.params?.workspaceId,
        states: ['pending.me'],
      },
    })
      .then((resp) => {
        this.contracts = resp.data;
      })
      .catch(() => {
        this.contracts = [];
      })
      .finally(() => {
        this.loadedContracts = true;
      });
  },
  methods: {
    onOpenUndoneDocument(documentId) {
      return this.$router.push({
        name: 'documentsDetail',
        params: {
          workspace_id: `${this.$route.params?.workspaceId}`,
          contract_id: `${documentId}`,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-skeleton-loader__text) {
  margin-bottom: 0;
  height: 17.5px;
}

.file-name__title {
  color: #424242;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17.5px;

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>
